/* React */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

/* BL */
import {
	singleCollectionButtons,
	collectionValidationSchema,
} from '../../../BusinessLogic/data/CollectionSingle';
import {
	getCollection,
	putCollection,
	organizeImageGrid,
	organizeArtworkGrid,
	getCollections,
	postCollection,
	getGalleryArtistsList,
	organizeSubCollections,
} from '../../../BusinessLogic/redux/reducers/collection';
import { setTitleInAlbumFiles } from '../../../BusinessLogic/redux/reducers/album';

import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { ENDPOINTS_DOCUMENT } from '../../../BusinessLogic/helpers/routes';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';

/* UI */
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';
import TabDocumentAndEdit from '../../../UI/organisms/Tabs/TabDocumentAndEdit';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import ImageAndTitle from '../../../UI/molecules/Image/ImageAndTitle';
import ModalSelectImage from '../../../UI/organisms/PopUp/ModalSelectImage';
import DropZoneModal from '../../../UI/molecules/DropZone/DropZoneModal';
import PageNotFound from '../../fallBackPages/PageNotFound';
import { ModalPostulation } from '../../../UI/organisms/PopUp/ModalPostulation';

/**
 * @description renders the single album section. It has an Image carousel and singleAlbumtTabs.
 * @param {*} param0
 * @returns
 */
const CollectionSingle = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const {
		collectionSingle,
		collectionSingleTabs,
		galleryCollectionSingleTabs,
		collectionState,
		collections,
		status,
	} = useSelector((state) => state.collections);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { appUser } = useSelector((state) => state.appUser);

	const [indexInfo, setIndexInfo] = useState(0);
	const [showSelectImageModal, setShowSelectImageModal] = useState(false);
	const [showDropzoneModal, setShowDropzoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const [showModalCannotDelete, setShowModalCannotDelete] = useState(false);

	useEffect(() => {
		dispatch(getCollection(id));
		dispatch(getGalleryArtistsList());
		//eslint-disable-next-line
	}, [id]);

	const isSubcollection = collectionSingle?.collection_type ? true : false;
	const isDefaultGeneralCollection = collectionSingle?.is_general_collection;
	const isGalleryUser = appUser.role_company === 'GALLERY';

	const singleTabs = filterCollectionTabs();

	const singleButtons = isDefaultGeneralCollection
		? singleCollectionButtons.filter(
				(tab) => tab !== 'SubColecciones' && tab !== 'Documentos'
		  )
		: singleCollectionButtons;

	useEffect(() => {
		if (singleTabs[indexInfo].content[0].field === 'collection_artworks') {
			dispatch(setTitleInAlbumFiles('Obras'));
		} else if (
			singleTabs[indexInfo].content[0].field === 'collection_documents'
		) {
			dispatch(setTitleInAlbumFiles('Documentos'));
		}
	}, [singleTabs, indexInfo, dispatch]);

	function filterCollectionTabs() {
		if (isGalleryUser) {
			if (isDefaultGeneralCollection) {
				return galleryCollectionSingleTabs.filter(
					({ tab }) => tab !== 'Subcolecciones' && tab !== 'Documentos'
				);
			} else {
				return galleryCollectionSingleTabs;
			}
		} else {
			if (isDefaultGeneralCollection) {
				return collectionSingleTabs.filter(
					({ tab }) => tab !== 'Subcolecciones' && tab !== 'Documentos'
				);
			} else {
				return collectionSingleTabs;
			}
		}
	}

	function onSubmitAction(values) {
		values['values'] = {
			...values['values'],
			organization_user_id: collectionSingle.organization_user_id,
			main_picture_id: collectionSingle.main_picture_id,
			type_parent: collectionSingle.type_parent,
		};
		dispatch(putCollection(values)).finally(() => dispatch(getCollection(id)));
	}

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'COLLECTION',
				type_id: id,
				organization_user_id: Number(collectionSingle.organization_user_id),
				file_type: 'IMAGE',
				new_collection: false,
			};
			await dispatch(postCollection(values)).finally(() => {
				dispatch(getCollection(id));
				dispatch(getCollections());
			});
		}
	};
	const deleteDocumentFunction = (documentId) => {
		let values = {
			element_id: collectionSingle.id,
			element: 'DOCUMENT',
			url: `${ENDPOINTS_DOCUMENT.DELETE_DOCUMENT}${documentId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getCollection(id)));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: collectionSingle.id,
			element: 'COLLECTION',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getCollection(id)));
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'COLLECTION',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => dispatch(getCollection(id)));
	};

	const submitOrganizeArtworksGrid = (sortableArtwork) => {
		let values = {
			artworks: sortableArtwork,
			type: 'COLLECTION',
			type_id: id,
			id,
		};
		dispatch(organizeArtworkGrid(values)).then(() =>
			dispatch(getCollection(id))
		);
	};

	const submitOrganizeSubCollections = (sortableSubCollections) => {
		const { organization_user_id } = localStorage;

		const newArray = sortableSubCollections.map((obj) => ({
			id: obj.id,
			order: obj.order,
		}));

		let values = {
			sort_subcollections: newArray,
			organization_user_id,
			id,
		};
		dispatch(organizeSubCollections(values)).then(() =>
			dispatch(getCollection(id))
		);
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};

	const renderSwitch = () => {
		const singleTab = JSON.parse(JSON.stringify(singleTabs[indexInfo]));
		const hasAuthorField = singleTab?.content?.find(
			(tab) => tab?.title === 'Autor'
		);
		if (isGalleryUser && isDefaultGeneralCollection && hasAuthorField) {
			singleTab.content[1].hideEditOption = true;
		}
		switch (singleTab.type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={singleTab}
						onSubmitAction={onSubmitAction}
						indexInfo={indexInfo}
						id={parseInt(id)}
						colorHr='green'
						validationSchema={
							collectionValidationSchema[indexInfo]
								? collectionValidationSchema[indexInfo]
								: null
						}
					/>
				);
			case 'Artworks':
				return (
					<TabImageAndEdit
						information={singleTab}
						indexInfo={indexInfo}
						color='green'
						AddIconLink={`/colecciones/${id}/archivos`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
						objectFitProp='contain'
						submitOrganizeArtworksGrid={submitOrganizeArtworksGrid}
						section={'Collection'}
						isSectionEditable={!isDefaultGeneralCollection}
					/>
				);

			case 'Documents':
				return (
					<TabDocumentAndEdit
						information={singleTab}
						indexInfo={indexInfo}
						color='green'
						saveLastSectionURL={true}
						AddIconLink={`/colecciones/${id}/archivos`}
						deleteDocumentFunction={deleteDocumentFunction}
					/>
				);
			case 'Subcolecciones':
				return isDefaultGeneralCollection ? null : (
					<TabImageAndEdit
						information={singleTab}
						indexInfo={indexInfo}
						section={'Collection'}
						color='green'
						submitOrganizeSubCollections={submitOrganizeSubCollections}
						AddIconLink={`/colecciones/${id}/new`}
						saveLastSectionURL={true}
						isSectionEditable={true}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={singleTab}
						indexInfo={indexInfo}
						color='green'
						createImagesFunction={createImagesFunction}
						deleteImagesFunction={deleteImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
						AddIconLink={`/${singleTab.tab.toLowerCase()}/new`}
						addImageAction={() => setShowSelectImageModal(true)}
					/>
				);

			default:
				return <h1>No hay album disponible</h1>;
		}
	};

	const checkRedirect = () => {
		let redirect = isSubcollection
			? `/colecciones/${collectionSingle.collection_type}`
			: '/colecciones';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) {
			dispatch(backSectionURL());
		}
	};

	const CheckTextBack = () => {
		const defaultMessage = 'RegresarColecciones';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const onDeleteAction = () => {
		navigate(`/colecciones/${collectionSingle.collection_type}`);
		returnBackAction();
	};

	const listItemsCollection = collectionState
		? collections
		: collectionSingle.sub_collections.filter((element) => element.id);

	if (
		collectionSingle?.code === 403 ||
		parseInt(collectionSingle?.code) === 404
	) {
		return <PageNotFound />;
	}

	return (
		<div>
			{showModalCannotDelete && (
				<ModalPostulation
					modalTitle={t('ColecciónNoPuedeEliminarse')}
					modalText={t('ColecciónNoPuedeEliminarseDescription')}
					buttonLabel={t('Cerrar')}
					modalClassName={'mx-auto w-4/5 px-8'}
					actionClose={() => setShowModalCannotDelete(false)}
				/>
			)}
			{(!collectionSingle || `${collectionSingle.id}` !== id) &&
				status === 'loading' && (
					<div className='-mt-4'>
						<ViewSingleSkeleton
							numberOfTitleAndFields={1}
							numberOfTabs={2}
							numberOfSingleRow={2}
							designCaption={'hidden'}
						/>
					</div>
				)}
			{collectionSingle && (
				<TemplateWithNavBar section='colecciones'>
					<NavbarSecondaryNew
						title={collectionSingle.name}
						color='bg-green-50'
						textColor='text-green-50'
						redirect={checkRedirect()}
						textPopup={`${t('EliminarColeccion')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarColeccion')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEstaColeccion')}`}
						modalTextTwo={elementInfo}
						id={parseInt(id)}
						section='colecciones'
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-green-50'
						backAction={() => returnBackAction()}
						arrowLeftColor='green'
						onDeleteAction={collectionSingle.collection_type && onDeleteAction}
						showDots={!isDefaultGeneralCollection}
						setShowModalCannotDelete={setShowModalCannotDelete}
					/>
					<ButtonFabExtendeNav
						id={parseInt(id)}
						listItems={listItemsCollection?.length ? listItemsCollection : []}
						section='colecciones'
					/>
					<div className='flex w-full flex-col gap-8 md:flex-row'>
						<div className='flex w-full flex-col p-6 md:w-1/3'>
							<ImageAndTitle
								imagen={collectionSingle.main_picture_url}
								objectFitProp={'scale-down'}
							/>
							<div className='flex justify-center p-4'>
								<ButtonFabExtendedPrimary
									text={t('CambiarImagenPortada')}
									className={'w-2/3 text-white'}
									action={() => setShowSelectImageModal(true)}
								/>
							</div>
						</div>
						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={singleButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
					{showSelectImageModal && (
						<ModalSelectImage
							setShowModal={setShowSelectImageModal}
							selectImageAction={() => {
								navigate(`/colecciones/${id}/portada`);
							}}
							uploadImageAction={() => {
								setShowSelectImageModal(false);
								setShowDropzoneModal(true);
							}}
						/>
					)}
					{showDropzoneModal && (
						<DropZoneModal
							showDropZoneModal={showDropzoneModal}
							setDropZoneFiles={setDropZoneFiles}
							dropZoneFiles={dropZoneFiles}
							collectAndCreateImage={() => createImagesFunction(dropZoneFiles)}
							maxFiles={1}
							setShowDropZoneModal={setShowDropzoneModal}
						/>
					)}
				</TemplateWithNavBar>
			)}
		</div>
	);
};

export default CollectionSingle;
