/* 3rd party */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import { addConfigurationChip } from '../../../BusinessLogic/redux/reducers/artistFilter';
import {
	setUniqueIdCollection,
	setPortfolioDataCreationIdCollection,
} from '../../../BusinessLogic/redux/reducers/portfolio';
import { changeCollectionState } from '../../../BusinessLogic/redux/reducers/collection';
import { multiPropsFilters } from '../Filter/Filter';

/* UI */
import ImageAndTitle from '../../molecules/Image/ImageAndTitle';
import ImageListContent from '../../molecules/Image/ImageListContent';
import TextTitleListContent from '../../molecules/Text/TextTitleListContent';
import TextWithLink from '../../molecules/Text/TextWithLink';
import ImageAndInfo from '../../molecules/Image/ImageAndInfo';
import GeneralCollectionCover from '../../assets/images/DefaultCollectionCover.png';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';

/**
 * @description render all images of the user
 * @param {*} param0
 * @returns
 */
export default function ImagesSectionWithFilter({
	data,
	filterChange,
	filterArray,
	redirect,
	changeView,
	listLabels,
	selectionSquare,
	newEntityLink,
	objectFitProp,
	selectionWithBorder,
	designContainerCards,
	designWrapperImageItem,
	onEmptyDataAction,
}) {
	const dispatch = useDispatch();
	const { uniqueIdCollection, portfolioDataCreation } = useSelector(
		(state) => state.portfolios
	);
	const [filteredElements, setFilteredElements] = useState(data);
	const { t } = useTranslation();

	useEffect(() => {
		data?.length > 0 &&
			setFilteredElements(multiPropsFilters(data, filterArray));
	}, [data, filterArray, filterChange]);

	useEffect(() => {
		const data = JSON.parse(JSON.stringify(filterArray));
		dispatch(addConfigurationChip(data));
	}, [dispatch, filterArray, filterChange]);

	return (
		<div className='container mx-auto'>
			{data?.code === 0 ? (
				<div className='mx-auto flex w-11/12'>
					<TextWithLink
						emptyEntityMessage={data.message}
						redirect={newEntityLink}
						onActionClick={onEmptyDataAction}
						text={data?.textEmpty}
						clickMessage={data?.textEmptyLink}
					/>
				</div>
			) : (
				<div>
					{changeView === true && listLabels && (
						<TextTitleListContent listLabels={listLabels} truncate />
					)}

					<div
						className={
							changeView
								? 'flex flex-col justify-center px-8'
								: `grid w-full grid-cols-3 justify-center gap-x-2 gap-y-0 px-4 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 ${designContainerCards}`
						}
						data-test='allEntity-grid'
					>
						{Array.isArray(filteredElements) &&
							filteredElements.map((element) =>
								changeView ? (
									<ImageListContent
										key={element.id}
										id={element.person_id || element.id}
										imagen={element.main_picture_url}
										title={element.person_name || element.name}
										name={element.first_name}
										lastName={element.last_name}
										email={element.email}
										rol={element.role}
										phone={element.phone_number}
										redirect={redirect}
										truncate
									/>
								) : (
									<div
										key={element.id}
										className='flex h-full flex-col justify-start'
									>
										{selectionWithBorder && (
											<div
												onClick={() => {
													if (
														uniqueIdCollection === element.id &&
														portfolioDataCreation.idCollection === element.id
													) {
														dispatch(setUniqueIdCollection(null));
														dispatch(
															setPortfolioDataCreationIdCollection(null)
														);
														return;
													}
													dispatch(setUniqueIdCollection(element.id));
													dispatch(
														setPortfolioDataCreationIdCollection(element.id)
													);
													dispatch(changeCollectionState(true));
												}}
												className='flex'
												key={element.id}
											>
												<ImageAndInfo
													title={element.person_name || element.name}
													imagen={
														element.is_general_collection
															? GeneralCollectionCover
															: element.main_picture_url
													}
													design='relative mx-auto'
													titleDesign='pb-2 !font-light'
													textContainerDesign='py-4 w-full'
													lazyLoadImageBorder={
														uniqueIdCollection === element.id &&
														portfolioDataCreation.idCollection === element.id
													}
													imageFormat={false}
													designWrapperImage={designWrapperImageItem}
												/>
											</div>
										)}
										{!selectionWithBorder && (
											<>
												{element.is_general_collection && (
													<TextContentSecond
														text={t('AutomáticamenteGenerado')}
														design={`text-green-50 mb-2 font-semibold h-[28px]`}
													/>
												)}
												<ImageAndTitle
													key={element.id}
													id={element.person_id || element.id}
													imagen={
														element.is_general_collection
															? GeneralCollectionCover
															: element.main_picture_url
													}
													isImageWithoutFormat={element.is_general_collection}
													title={element.person_name || element.name}
													redirect={redirect}
													selectionSquare={selectionSquare}
													objectFitProp={objectFitProp}
													designContainer={`${
														!element.is_general_collection && 'mt-[36px]'
													}`}
													designTitle='font-semibold'
												/>
												{element.is_general_collection && (
													<TextContentSecond
														text={t('CreamosEstaColecciónGeneral')}
														design='text-grey-50'
													/>
												)}
											</>
										)}
									</div>
								)
							)}
					</div>
				</div>
			)}
		</div>
	);
}

ImagesSectionWithFilter.propTypes = {
	/* array of object with the data to render */
	data: PropTypes.array,
	/* handle the filter state */
	filterChange: PropTypes.number,
	/* array with the filter objects */
	filterArray: PropTypes.object,
	/* name to filter */
	nameFilter: PropTypes.string,
	/* url redirect to specific document */
	redirect: PropTypes.string,
	/* boolean that represent the state of the changeview */
	changeView: PropTypes.bool,
	/* array with labels  used in the render list items with name*/
	listLabels: PropTypes.array,
	/* only used in portfolio to render clicked square selection */
	selectionSquare: PropTypes.bool,
	/** Boolean prop  used to determine whether the TextContentSecond.js has a truncate class */
	truncate: PropTypes.bool,
	/* link to new entity create */
	newEntityLink: PropTypes.string,
	/** determines the object fit of the pre-visualization images */
	objectFitProp: PropTypes.string,
	/** determine the select way as border */
	selectionWithBorder: PropTypes.bool,
	/** styles for container cards */
	designContainerCards: PropTypes.string,
	/** function to be run when data is empty */
	onEmptyDataAction: PropTypes.func,
};

ImagesSectionWithFilter.defaultProps = {
	nameFilter: '',
	objectFitProp: 'cover',
};
